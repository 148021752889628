/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import IncludedProducts from '~/components/Kit/IncludedProducts'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductIngredients from '~/components/Product/Ingredients'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import ProductQuantitySelector from '~/components/Product/QuantitySelector'

const KitDetails = ({ kit, ...props }) => {
  const {
    name,
    description,
    price,
    regularPrice,
    comingSoon,
    soldOut,
    ingredients = null,
    products
  } = kit

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  // Initialize the current sku with the first variant available
  // for each product in the kit
  const [currentSku, setCurrentSku] = useState(
    products
      .map(({ variants }) => variants[0].sku)
      .sort()
      .join('|')
  )

  return (
    <Box
      {...props}
      sx={{
        textAlign: ['center', null, 'left'],
        margin: 'auto',
        marginTop: ['8px', null, '48px'],
        maxWidth: ['650px !important', null, '50% !important']
      }}
    >
      <Heading
        as="h1"
        variant="h2"
        sx={{
          marginTop: ['20px', null, '0'],
          marginBottom: ['4px', null, '24px'],
          maxWidth: ['100%', null, '75%']
        }}
      >
        {name}
      </Heading>

      <Flex
        py="3"
        sx={{
          display: 'inline-flex',
          marginLeft: ['-10px', null, 0]
        }}
      >
        <ProductQuantitySelector
          handleChange={value => setSelectedQuantity(parseInt(value))}
        />
        <ProductAddToCartButton
          sx={{
            marginLeft: ['12px', null, '16px']
          }}
          soldOut={soldOut}
          comingSoon={comingSoon}
          price={price}
          quantity={selectedQuantity}
          regularPrice={regularPrice}
          sku={currentSku}
        />
      </Flex>

      {(soldOut || comingSoon) && <ProductOutOfStock sku={currentSku} />}

      <div
        sx={{
          '& p': {
            marginTop: '8px',
            marginBottom: '28px'
          }
        }}
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.html
        }}
      />

      {products && (
        <IncludedProducts
          products={products}
          handleProductChange={setCurrentSku}
        />
      )}

      {ingredients && <ProductIngredients ingredients={ingredients} />}
    </Box>
  )
}

KitDetails.propTypes = {
  kit: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    ingredients: PropTypes.shape({})
  })
}

export default KitDetails
