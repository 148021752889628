/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx } from 'theme-ui';
import ProductOptionSelector from '~/components/Product/OptionSelector';

const ProductOptions = ({ allOptions = [], currentValues = [], handleChange = {} }) => {
  const getValueForOptionType = ({ slug }) => {
    const results = currentValues.filter((option) => option.option_type[0].slug === slug);
    return results.length > 0 ? results[0].slug : '';
  };

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        textAlign: 'left',
      }}
    >
      {allOptions.map((option) => (
        <ProductOptionSelector
          sx={{
            marginTop: ['0.75rem', null],
          }}
          key={option.slug}
          type={option.slug}
          title={option.presentation}
          values={option.optionValues}
          selectedValue={getValueForOptionType(option)}
          handleChange={(option) => handleChange(option)}
        />
      ))}
    </Flex>
  );
};

ProductOptions.propTypes = {
  allOptions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      presentation: PropTypes.string,
      optionValues: PropTypes.array,
    })
  ),
  currentValues: PropTypes.arrayOf(PropTypes.shape({})),
  handleChange: PropTypes.func,
};

export default ProductOptions;
