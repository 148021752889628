/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Flex, jsx } from 'theme-ui';
import ProductOptions from '~/components/Product/Options';
import useProductVariants from '~/hooks/components/use-product-variants';

const IncludedProduct = ({ product, handleVariantChange }) => {
  const { trackProductClicked } = useAnalytics();

  const { slug, variants, optionTypes } = product;

  const { currentVariant, selectVariant } = useProductVariants(variants);

  const { name, mainImage } = currentVariant;

  useEffect(() => {
    handleVariantChange(product, currentVariant);
  }, [handleVariantChange, product, currentVariant]);

  return (
    <Flex
      key={slug}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: 0,
        border: '1px solid',
        backgroundColor: 'white',
        height: '100%',
      }}
    >
      <Link to={`/products/${slug}/`} onClick={() => trackProductClicked(product)}>
        <GatsbyImage
          image={mainImage.gatsbyImageData}
          alt={mainImage.title}
          key={mainImage.title}
          sx={{ minWidth: '180px', height: '100%' }}
        />
      </Link>

      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column'],
          alignItems: 'flex-start',
          margin: '0 1rem',
        }}
      >
        <Link
          sx={{
            display: 'block',
            textTransform: 'none',
            variant: ['text.h4'],
          }}
          to={`/products/${slug}/`}
        >
          {name}
        </Link>

        {optionTypes && (
          <ProductOptions
            allOptions={optionTypes}
            currentValues={currentVariant.optionValues}
            handleChange={selectVariant}
          />
        )}
      </Flex>
    </Flex>
  );
};

IncludedProduct.propTypes = {
  product: PropTypes.shape({}).isRequired,
  handleVariantChange: PropTypes.func.isRequired,
};

export default IncludedProduct;
